import React from 'react';
import '../group/group5.css';
import ReactPlayer from 'react-player';

export default function Group5() {
    return (
        <div className='group-5'>
            <hr className='line-4'></hr>
            <h1>Our Story</h1>
            <div className='group-5-video'>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=qsQiKB-9Gpo"
                    controls
                    playing={true}
                    loop={true}
                />
            </div>
        </div>
    );
}
