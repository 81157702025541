import React from 'react';
import '../group/group4.css';
import Rectangle23 from '../logo/why_mcy_grp.jpg';



export default function Group6() {
    return (
      <div className='group-4'>
        <hr className='line-4'></hr>
        <h2>Why McYouniverse Group?</h2>
        <div className='group-4-content'>
          <div className='content-left-side'>
            <h3 className='bold'>We are Solutions-Providers </h3>
            <p className='small'>McYouniverse Group provides value in properties, 
              ensures food security, simplifies tech solutions, thereby building long-term business relationships with customers from different walks of life.
            </p>
            <hr className='line-5'></hr>
            <div className='track'>
              <div className='land'>
                <p>Estate Management</p>
                <h1>4K+</h1>
              </div>

              < hr className='line-6'></hr>
              
              <div className='t-p'>
                <p>Trained Persons</p>
                <h1>7.5K+</h1>
              </div>
              
               <hr className='line-6'></hr>
              
              <div className='b-d'>
                <p>Business Developed</p>
                <h1>350+</h1>
              </div>
            </div>
          </div>
          <div className='group-4-img'>
            <img src={Rectangle23} alt='page-4-img' />
          </div>
        </div>    
      </div>
    )
}