import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './BoardDirector.css';
import DrMike from '../logo/Dr Egbekoya.jpeg';
import MrsEgbekoya from '../logo/Mrs Egbekoya.jpeg';
import MissBukola from '../logo/Miss Bukola.jpeg';
import MrCaleb from '../logo/Mr Caleb new.jpeg';


const BoardDirector = () => {
  const items = [
    { imageUrl: DrMike, title: 'Michael Egbekoya' },
    { imageUrl: MrsEgbekoya, title: 'Folashade Egbekoya' },
    { imageUrl: MrCaleb, title: 'Caleb Atere' },
    { imageUrl: MissBukola, title: 'Oluwabukola Ajayi' },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          arrows: false,
        }
      }
    ]
  };

  return (
    <div className='bd-context'>
      <div className='head-txt'>
        <h1>Board of Directors</h1>
      </div>
      <div className='members-box'>
        <Slider {...settings}>
          {items.map((item, index) => (
            <div key={index} className='board-member'>
              <img src={item.imageUrl} alt={item.title}  />
              <h3>{item.title}</h3>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BoardDirector;
