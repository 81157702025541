import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../App.css';
import { Footer } from '../Footer';
import visionImage from '../logo/vision.jpg';
import missionImage from '../logo/mission.jpg';
import coreValuesImage from '../logo/core values.jpg';
import BoardDirector from '../group/BoardDirector';
import Rectangle23 from '../logo/why_mcy_grp.jpg';

export default function About() {

  const sliderRef = useRef(null);

  const slides = [
    {
      imageUrl: visionImage,
      title: 'OUR VISION',
      description: 'To be a Leading Solutions-Providing Business Conglomerate of Real Estate, Agriculture, Information Technology, Haulage and Logistics in the Universe.',
      backgroundColor: '#9A031E',
      link: '/our-vision'
    },
    {
      imageUrl: missionImage,
      title: 'OUR MISSION',
      description: 'To integrate efficacy across diverse sectors of the economy, leading individuals to a wide array of solutions within and beyond Africa.',
      backgroundColor: '#9A031E',
      link: '/our-mission'
    },
    {
      imageUrl: coreValuesImage,
      title: 'OUR CORE VALUES',
      description: `
        <div class="core-value"><span class="core-heading">VIGOR</span> We deliver with efficacy and policy.</div>
        <div class="core-value"><span class="core-heading">INTEGRITY</span> We act in utmost good faith, we deliver on our words.</div>
        <div class="core-value"><span class="core-heading">NETWORK</span> We believe business thrive based on smooth and ideal company-client flow.</div>
        <div class="core-value"><span class="core-heading">EXCELLENCE</span> We crave for and offer top-notch services.</div>`,
      backgroundColor: '#9A031E',
      link: '/our-core-value',
      isCoreValues: true
    },
  ];

  const settings = {
    className: "center",
    dots: false,
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerPadding: "160px",
    speed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          centerPadding: "0px",
        }
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        }
      },
    ]
  };


  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  return (
    <div className='about'>
      <div className='first-content'>
        <div className='about-img'>
          <img src={Rectangle23} alt='page-4-img' />
        </div>
        <div className='about-content'>
          <h1>We are A Team of Solutions-Providers</h1>
          <h2>Integrating Efficacy Across Diverse Sectors of The Economy</h2>
          <div className='about-info'>
            <p>At McYouniverse Group, we have through the years proffered swift-paced solutions spanning across distinct sectors of the economy,
              one-for-all subsidiary at a time. Even more, we are committed to our passion for development in Africa and beyond, thereby utilizing 
              our expertise and resources towards creating sustainable impact in the universe.
            </p>
            <p>With a passion to integrate efficacy across diverse sectors of the economy, we are leading individuals to a wide array of solutions across the Real Estate,
              Agriculture, Information Technology, Haulage and Logistics Sectors within and beyond Africa
            </p>
          </div>
          
        </div>
      </div>

      <div className='bold-text'>
        <p>“If the Youniverse does not need us, we would not be here.”</p>
      </div>
    
    
      <div className="group-2">
        <div className="control">
          <div className="previous">
            <button onClick={handlePrev}>&#8592;</button>
          </div>
          <div className="next">
            <button onClick={handleNext}>Next &#8594;</button>
          </div>
        </div>
        <Slider {...settings} ref={sliderRef}>
          {slides.map((slide, index) => (
            <div key={index} className='group-2-slide'>
              <div className="slide-info" style={{ backgroundColor: slide.backgroundColor }}>
                <h1>{slide.title}</h1>
                <div className={`description ${slide.isCoreValues ? 'core-values-grid' : ''}`} dangerouslySetInnerHTML={{ __html: slide.description }} />
              </div>
              <img src={slide.imageUrl} alt='property' />
            </div>
          ))}
        </Slider>
      </div>
      <BoardDirector/>
      
      <Footer/>
    
    </div>
    
  );

}
