import React from 'react';
import '../../App.css';
import { Footer } from '../Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../logo/images', false, /\.(png|jpe?g|svg)$/));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        dots: true,
        arrows: false,
      }
    }
  ]
};

export default function Realestate() {
  return (
    <div className='real-estate'>
      <div className='realEstate'>
        <h1>Simplifying Investment Opportunities for All</h1>
        <h3>Providing Access to Well-timed and Problem-free Real Estate Investment</h3>
      </div>
      <div className='realEstate-content'>
      <div className="realEstate-Images">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img className="slider-image" src={image.default || image} alt={`RealEstate Slide ${index}`} />
              </div>
            ))}
          </Slider>
        </div>
        <div className='realEstate-info'>
          <h1>McYouniverse Properties</h1>
          <p>
            We provide a diverse array of contemporary housing units across all our estate developments in a bid to cater 
            for our customers’ needs, ranging from people looking to live in a modern and sustainable environment in 
            strategic locations within Nigeria, to people looking to make high-yielding property investments within the country.
          </p>
        </div>
        
      </div>
      <Footer />
    </div>
  );
}
