import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import '../group/group6.css';

export default function Group6() {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const slides = [
    {
      content: {
        quote: '“In a country like Nigeria, it is often times difficult to put a huge sum of money on land investment. I feel safe with McYouniverse Group, and I am glad to build a business relationship with them for about 2 years now.”',
        name: 'Dolapo Shittu',
      }
    },
    {
      content: {
        quote: '“I love the growth I am seeing every day with McYouniverse Group. The team is doing well, and it’s great to see. I can proudly say I am a satisfied customer, because they always deliver on their words. It is inspiring, I must commend.”',
        name: 'Shade Omoleye',
      }
    },
    {
      content: {
        quote: '“Enrolling to learn Front-end Web Development with Codeverse Africa has been the best decision ever. The learning process is seamless, and the tutors are ever-ready to transfer knowledge, equipping me to be able to put knowledge to use.”',
        name: 'Isaac Giwa',
      }
    }
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: '0px',
    afterChange: current => setActiveIndex(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '130px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '60px',
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '90px',
        }
      }
    ]
  };

  const handlePrev = () => {
    setActiveIndex(prevIndex => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    setActiveIndex(prevIndex => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    sliderRef.current.slickNext();
  };

  return (
    <div className='group-6'>
      <div className='head-text'>
        <hr className='line-4'></hr>
        <h1>Satisfied Customers Share Their Stories</h1>
      </div>
      <div>
        <Slider {...settings} ref={sliderRef}>
          {slides.map((slide, index) => (
            <div key={index} className={`flex-item ${activeIndex === index ? 'active' : ''}`}>
              <div className='box'>
                <p>{slide.content.quote}</p>
                <div className='ref-contact'>
                  <p>{slide.content.name}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className='group-3-line'>
        <hr className='line-3'></hr>
        <div className='line-3-indicator' style={{ transform: `translateX(${activeIndex * 100}%)` }}></div>
      </div>
      <div className='group-3-control'>
        <button className='previous' onClick={handlePrev}>&#8592;</button>
        <button className='next' onClick={handleNext}>Next &#8594;</button>
      </div>
    </div>
  );
}
